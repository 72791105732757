import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const blocks = document.querySelectorAll('.testimonials-carousel-block');

  if (0 < blocks.length) {
    blocks.forEach((block) => {
      const carousel = block.querySelector('.testimonials-carousel__carousel');

      new Swiper(carousel, {
        slidesPerView: 1.3,
        spaceBetween: 20,
        navigation: {
          prevEl: block.querySelector('.swiper-button-prev'),
          nextEl: block.querySelector('.swiper-button-next'),
        },

        breakpoints: {
          768: {
            slidesPerView: 1.4,
            spaceBetween: 32,
          },
          1200: {
            slidesPerView: 1.75,
            spaceBetween: 32,
          },
        },
      });
    });
  }
});
